import { z } from 'zod';

export const SHUTTERSTOCK_SOURCE_NAME = 'shutterstock';

export const SourceAssetCategorySchema = z.object({
  source: z.string(),
  sourceCategoryID: z.string(),
  sourceCategoryName: z.string(),
});
export type SourceAssetCategory = z.infer<typeof SourceAssetCategorySchema>;

export enum AssetType {
  Audio = 'audio',
  Image = 'image',
  Video = 'video',
}

export enum AssetLicensePurpose {
  Preview = 'preview',
  Render = 'render',
}

export const AssetSchema = z.object({
  addedDate: z.string().or(z.null()),
  categories: z.array(SourceAssetCategorySchema),
  description: z.string(),
  fileSize: z.number(),
  keywords: z.array(z.string()),
  previewURL: z.string().or(z.null()),
  searchID: z.string(),
  source: z.literal(SHUTTERSTOCK_SOURCE_NAME),
  sourceAssetID: z.string(), // Original asset ID in Shutterstock
  thumbURL: z.string().or(z.null()),
  height: z.number(),
  width: z.number(),
});
export type Asset = z.infer<typeof AssetSchema>;

export const AssetLicenseSchema = z.object({
  assetID: z.string(), // Our ID for the asset
  assetType: z.nativeEnum(AssetType),
  licensee: z.string(), // Waymark account GUID
  purpose: z.nativeEnum(AssetLicensePurpose),
  sourceAssetID: z.string(), // Original asset ID in Shutterstock
  sourceDownloadURL: z.string(), // URL from which the source asset can be downloaded
  sourceLicenseID: z.string(), // The ID of the license in Shutterstock
});
export type AssetLicense = z.infer<typeof AssetLicenseSchema>;

// Response types
export interface AssetLicenseResponse<AssetLicenseT extends AssetLicense> {
  assetLicenses: AssetLicenseT[];
}

export interface AssetSearchResponse<AssetT extends Asset> {
  page: number;
  per_page: number;
  total_count: number;
  assets: AssetT[];
}

export interface AssetDownloadResponse {
  assetID: string;
  bucket: string;
}
