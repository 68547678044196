import { z } from 'zod';
import { VideoSize } from './video';
import { ShutterstockError, ShutterstockPrice, ShutterstockURL } from './shutterstockApi';

// Video search types and enums
export enum ShutterstockVideoAspectRatio {
  FourByThree = '4_3',
  NineBySixteen = '16_9',
  Nonstandard = 'nonstandard',
}

export enum ShutterstockVideoResolution {
  FourK = '4k',
  HighDefinition = 'high_definition',
  StandardDefinition = 'standard_definition',
}

export enum ShutterstockVideoSearchSort {
  Newest = 'newest',
  Popular = 'popular',
  Random = 'random',
  Relevance = 'relevance',
}

export enum ShutterstockVideoOrientation {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export enum ShutterstockVideoSearchParameters {
  AspectRatio = 'aspect_ratio',
  Category = 'category',
  DurationFrom = 'duration_from',
  DurationTo = 'duration_to',
  License = 'license',
  Orientation = 'orientation',
  Page = 'page',
  PerPage = 'per_page',
  Query = 'query',
  Resolution = 'resolution',
  Safe = 'safe',
  Sort = 'sort',
  View = 'view',
}

export interface ShutterstockVideoFormat {
  display_name: string;
  file_size: number;
  format: string;
  fps: number;
  height: number;
  is_licensable: boolean;
  name: string;
  width: number;
}

export interface ShutterstockVideoAssetMap {
  '4k'?: ShutterstockVideoFormat;
  hd?: ShutterstockVideoFormat;
  preview_jpg?: ShutterstockURL;
  preview_mp4?: ShutterstockURL;
  preview_webm?: ShutterstockURL;
  sd?: ShutterstockVideoFormat;
  thumb_jpg?: ShutterstockURL;
  thumb_jpgs?: { urls: string[] };
  thumb_mp4?: ShutterstockURL;
  thumb_webm?: ShutterstockURL;
  web?: ShutterstockVideoFormat;
}

export interface ShutterstockVideo {
  added_date: string;
  aspect: number;
  aspect_ratio: string;
  assets: ShutterstockVideoAssetMap;
  categories: Array<{ id: string; name: string }>;
  contributor: { id: string };
  description: string;
  duration: number;
  id: string;
  keywords: string[];
  media_type: 'video';
}

export interface ShutterstockVideoSearchSpellcheckInfo {
  spellchecked_query?: string;
}

export interface ShutterstockVideoSearchResponse {
  data: ShutterstockVideo[];
  page: number;
  per_page: number;
  search_id: string;
  spellcheck_info: ShutterstockVideoSearchSpellcheckInfo;
  total_count: number;
}

// Parameters the Shutterstock video search endpoint will accept.
export const VideoSearchQueryParametersSchema = z
  .object({
    aspect_ratio: z.nativeEnum(ShutterstockVideoAspectRatio).optional(),
    category: z
      .string()
      .regex(/^([A-Za-z]+|[0-9]+)$/, {
        message: 'Must be a single Shutterstock category name or ID',
      })
      .optional(),
    duration_from: z.coerce.number().int().gte(1).optional(),
    duration_to: z.coerce.number().int().gte(1).optional(),
    orientation: z.nativeEnum(ShutterstockVideoOrientation).optional(),
    page: z.coerce.number().int().gte(1).optional(),
    per_page: z.coerce.number().int().gte(1).lte(500).optional(),
    query: z.string(),
    resolution: z
      .union([
        z.nativeEnum(ShutterstockVideoResolution),
        z.array(z.nativeEnum(ShutterstockVideoResolution)),
      ])
      .optional(),
    sort: z.nativeEnum(ShutterstockVideoSearchSort).optional(),
  })
  .strict()
  .refine(
    (schema) => {
      if (schema.duration_from && schema.duration_to) {
        return schema.duration_from <= schema.duration_to;
      }
      return true;
    },
    {
      message: 'duration_from must be less than or equal to duration_to',
    },
  );

export type VideoSearchQueryParameters = z.infer<typeof VideoSearchQueryParametersSchema>;

// License-related types and schemas
export interface ShutterstockLicenseVideoResult {
  video_id: string;
  allotment_charge: number;
  download: ShutterstockURL;
  error: string;
  license_id: string;
  price: ShutterstockPrice;
}

export interface ShutterstockLicenseVideoResponse {
  data: ShutterstockLicenseVideoResult[];
  errors?: ShutterstockError[];
  message: string;
  page: number;
  per_page: number;
  total_count: number;
}

export const ShutterstockLicenseVideoRequestSchema = z.object({
  videos: z.array(
    z.object({
      metadata: z.object({
        customer_id: z.string(),
        search_id: z.string(),
      }),
      size: z.nativeEnum(VideoSize),
      subscription_id: z.string(),
      video_id: z.string(),
    }),
  ),
});

export type ShutterstockLicenseVideoRequest = z.infer<typeof ShutterstockLicenseVideoRequestSchema>;
