import { z } from 'zod';
import { AssetLicensePurpose, AssetLicenseSchema, AssetSchema, AssetType } from './asset';
import { ImageSize } from './shutterstockImageApi';

export const ImageSchema = AssetSchema.extend({
  aspect: z.number(),
});
export type Image = z.infer<typeof ImageSchema>;

export const ImageAssetLicenseSchema = AssetLicenseSchema.extend({
  assetType: z.literal(AssetType.Image),
});
export type ImageAssetLicense = z.infer<typeof ImageAssetLicenseSchema>;

export const LicenseImageRequestImageSchema = z.object({
  licensee: z.string(),
  source_asset_id: z.string(),
  purpose: z.nativeEnum(AssetLicensePurpose),
  size: z.nativeEnum(ImageSize),
  source_search_id: z.string(),
});
export type LicenseImageRequestImage = z.infer<typeof LicenseImageRequestImageSchema>;

export const LicenseImageRequestSchema = z.object({
  images: z.array(LicenseImageRequestImageSchema),
});
export type LicenseImageRequest = z.infer<typeof LicenseImageRequestSchema>;

export const ImageAssetDownloadRequestSchema = z.object({
  asset: ImageSchema,
  license: ImageAssetLicenseSchema,
});
export type ImageAssetDownloadRequest = z.infer<typeof ImageAssetDownloadRequestSchema>;

export const ProcureImageRequestSchema = z.object({
  images: z.array(
    z.object({
      sourceAsset: ImageSchema,
      licensee: z.string(),
      purpose: z.nativeEnum(AssetLicensePurpose),
      size: z.nativeEnum(ImageSize),
    }),
  ),
});
export type ProcureImageRequest = z.infer<typeof ProcureImageRequestSchema>;

export const ProcureImageResponseResultSchema = z.object({
  sourceAsset: ImageSchema,
  assetID: z.string(),
  bucket: z.string(),
  key: z.string(),
});
export type ProcureImageResponseResult = z.infer<typeof ProcureImageResponseResultSchema>;

export const ProcureImageResponseSchema = z.object({
  results: z.array(ProcureImageResponseResultSchema),
});
export type ProcureImageResponse = z.infer<typeof ProcureImageResponseSchema>;
