export interface ShutterstockError {
  code: string;
  data: string;
  items: unknown[];
  message: string;
  path: string;
}

export enum ShutterstockLicense {
  Commercial = 'commercial',
  Editorial = 'editorial',
}

export interface ShutterstockPrice {
  local_amount: number;
  local_currency: string;
}

export enum ShutterstockView {
  Full = 'full',
  Minimal = 'minimal',
}

export type ShutterstockCategory = {
  id: string;
  name: string;
};

export type ShutterstockContributor = {
  id: string;
};

export type ShutterstockModel = {
  id: string;
};

export type ShutterstockRelease = {
  id: string;
};

export type ShutterstockURL = {
  url: string;
};

export type ShutterstockURLs = {
  urls: string[];
};
