import { z } from 'zod';
import { AssetLicensePurpose, AssetLicenseSchema, AssetSchema, AssetType } from './asset';

// Video specific schemas
export enum VideoSize {
  FourK = '4k',
  HighDefinition = 'hd',
  StandardDefinition = 'sd',
  Web = 'web',
}

export const VideoSchema = AssetSchema.extend({
  aspect_ratio: z.string(),
  length: z.number(),
});
export type Video = z.infer<typeof VideoSchema>;

// This is a source Video asset plus our local asset ID (the VPS key). Ivory needs to record these.
export const LicensedVideoSchema = VideoSchema.merge(AssetLicenseSchema.pick({ assetID: true }));
export type LicensedVideo = z.infer<typeof LicensedVideoSchema>;

export const VideoAssetLicenseSchema = AssetLicenseSchema.extend({
  assetType: z.literal(AssetType.Video),
  size: z.nativeEnum(VideoSize),
});
export type VideoAssetLicense = z.infer<typeof VideoAssetLicenseSchema>;

export const LicenseVideoRequestVideoSchema = z.object({
  licensee: z.string(),
  source_asset_id: z.string(),
  purpose: z.nativeEnum(AssetLicensePurpose),
  size: z.nativeEnum(VideoSize),
  source_search_id: z.string(),
});
export type LicenseVideoRequestVideo = z.infer<typeof LicenseVideoRequestVideoSchema>;

export const LicenseVideoRequestSchema = z.object({
  videos: z.array(LicenseVideoRequestVideoSchema),
});
export type LicenseVideoRequest = z.infer<typeof LicenseVideoRequestSchema>;

export const ProcureVideoRequestSchema = z.object({
  videos: z.array(
    z.object({
      sourceAsset: VideoSchema,
      licensee: z.string(),
      purpose: z.nativeEnum(AssetLicensePurpose),
      size: z.nativeEnum(VideoSize),
    }),
  ),
});
export type ProcureVideoRequest = z.infer<typeof ProcureVideoRequestSchema>;

export const ProcureVideoResponseSchema = z.object({
  results: z.array(LicensedVideoSchema),
});
export type ProcureVideoResponse = z.infer<typeof ProcureVideoResponseSchema>;
