import { z } from 'zod';
import { ShutterstockError, ShutterstockPrice, ShutterstockURL } from './shutterstockApi';

// Image specific schemas
export enum ImageSize {
  Custom = 'custom',
  Huge = 'huge',
  Medium = 'medium',
  Small = 'small',
  Vector = 'vector',
}

export interface ShutterstockImageSizeDetails {
  height: number;
  width: number;
  display_name?: string;
  dpi?: number;
  file_size?: number;
  format?: string;
  is_licensable?: boolean;
}

export interface ShutterstockImageThumbnail {
  height: number;
  url: string;
  width: number;
}

export interface ShutterstockImageAssetMap {
  huge_jpg?: ShutterstockImageSizeDetails;
  huge_thumb: ShutterstockImageThumbnail;
  large_thumb: ShutterstockImageThumbnail;
  medium_jpg?: ShutterstockImageSizeDetails;
  mosaic: ShutterstockImageThumbnail;
  preview: ShutterstockImageThumbnail;
  preview_1000?: ShutterstockImageThumbnail;
  preview_1500: ShutterstockImageThumbnail;
  small_jpg?: ShutterstockImageSizeDetails;
  small_thumb: ShutterstockImageThumbnail;
}

export interface ShutterstockImage {
  added_date: string;
  aspect: number;
  assets: ShutterstockImageAssetMap;
  categories: Array<{ id: string; name: string }>;
  contributor: { id: string };
  description: string;
  id: string;
  keywords: string[];
  media_type: 'image';
}

// Define search-related types and functions
export enum ShutterstockImageSearchSort {
  Newest = 'newest',
  Popular = 'popular',
  Random = 'random',
  Relevance = 'relevance',
}

export enum ShutterstockImageSearchParameters {
  AspectRatio = 'aspect_ratio',
  AspectRatioMax = 'aspect_ratio_max',
  AspectRatioMin = 'aspect_ratio_min',
  Category = 'category',
  HeightFrom = 'height_from',
  HeightTo = 'height_to',
  ImageType = 'image_type',
  License = 'license',
  Orientation = 'orientation',
  Page = 'page',
  PerPage = 'per_page',
  Query = 'query',
  Safe = 'safe',
  Sort = 'sort',
  View = 'view',
  WidthFrom = 'width_from',
  WidthTo = 'width_to',
}

export enum ShutterstockImageSearchOrientation {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export enum ShutterstockImageSearchImageType {
  Illustration = 'illustration',
  Photo = 'photo',
  Vector = 'vector',
}

export const ImageSearchQueryParametersSchema = z
  .object({
    aspect_ratio: z.coerce.number().gt(0).optional(),
    aspect_ratio_max: z.coerce.number().gt(0).optional(),
    aspect_ratio_min: z.coerce.number().gte(0).optional(),
    category: z
      .string()
      .regex(/^([A-Za-z]+|[0-9]+)$/, {
        message: 'Must be a single Shutterstock category name or ID',
      })
      .optional(),
    height_from: z.coerce.number().int().gte(0).optional(),
    height_to: z.coerce.number().int().gt(0).optional(),
    image_type: z
      .nativeEnum(ShutterstockImageSearchImageType)
      .optional()
      .default(ShutterstockImageSearchImageType.Photo),
    orientation: z.nativeEnum(ShutterstockImageSearchOrientation).optional(),
    page: z.coerce.number().int().gte(1).optional(),
    per_page: z.coerce.number().int().gte(1).lte(500).optional(),
    query: z.string(),
    sort: z.nativeEnum(ShutterstockImageSearchSort).optional(),
    width_from: z.coerce.number().int().gte(0).optional(),
    width_to: z.coerce.number().int().gt(0).optional(),
  })
  .strict()
  .refine(
    (schema) => {
      if (schema.aspect_ratio_min && schema.aspect_ratio_max) {
        return schema.aspect_ratio_min <= schema.aspect_ratio_max;
      }
      return true;
    },
    {
      message: 'aspect_ratio_min must be less than or equal to aspect_ratio_max',
    },
  )
  .refine(
    (schema) => {
      if (schema.height_from && schema.height_to) {
        return schema.height_from <= schema.height_to;
      }
      return true;
    },
    {
      message: 'height_from must be less than or equal to height_to',
    },
  )
  .refine(
    (schema) => {
      if (schema.width_from && schema.width_to) {
        return schema.width_from <= schema.width_to;
      }
      return true;
    },
    {
      message: 'width_from must be less than or equal to width_to',
    },
  );
export type ImageSearchQueryParametersInput = z.input<typeof ImageSearchQueryParametersSchema>;
export type ImageSearchQueryParameters = z.infer<typeof ImageSearchQueryParametersSchema>;

export interface ShutterstockImageSearchSpellcheckInfo {
  spellchecked_query?: string;
}

export interface ShutterstockImageSearchResponse {
  data: ShutterstockImage[];
  page: number;
  per_page: number;
  search_id: string;
  spellcheck_info: ShutterstockImageSearchSpellcheckInfo;
  total_count: number;
}

// License-related types and schemas
export interface ShutterstockLicenseImageResult {
  image_id: string;
  allotment_charge: number;
  download: ShutterstockURL;
  error: string;
  license_id: string;
  price: ShutterstockPrice;
}

export interface ShutterstockLicenseImageResponse {
  data: ShutterstockLicenseImageResult[];
  errors?: ShutterstockError[];
  message: string;
  page: number;
  per_page: number;
  total_count: number;
}

export const ShutterstockLicenseImageRequestSchema = z.object({
  images: z.array(
    z.object({
      metadata: z.object({
        customer_id: z.string(),
        search_id: z.string(),
      }),
      size: z.nativeEnum(ImageSize),
      subscription_id: z.string(),
      image_id: z.string(),
    }),
  ),
});

export type ShutterstockLicenseImageRequest = z.infer<typeof ShutterstockLicenseImageRequestSchema>;
