import { AxiosInstance } from 'axios';

import { ServiceDiscoverySDK } from '@libs/service-discovery-sdk';
import { AxiosSignedConnectionSource } from '@libs/service-access-lib';

import type { Service, WaymarkServiceAccessKey } from '@libs/shared-types';
import {
  AssetLicensePurpose,
  ImageSize,
  Video,
  VideoSize,
  AssetSearchResponse,
  Image,
  ImageSearchQueryParameters,
  ProcureImageRequest,
  ProcureImageResponse,
  ProcureVideoRequest,
  ProcureVideoResponse,
  VideoSearchQueryParameters,
} from 'libs/shutterstock-types-ts';

import store from 'app/state/store';
import * as selectors from 'app/state/selectors/index.js';

/**
 * Service for managing Shutterstock assets.
 */
class ShutterstockService {
  private serviceAccessKey: WaymarkServiceAccessKey | null = null;
  private serviceConfiguration: Service | null = null;
  private connection: AxiosInstance | null = null;

  /**
   * Creates a signed connection source which we can use to hit the service API.
   *
   * @param {WaymarkServiceAccessKey} serviceAccessKey
   */
  private async getConnection(serviceAccessKey: WaymarkServiceAccessKey): Promise<AxiosInstance> {
    if (this.connection && serviceAccessKey === this.serviceAccessKey) {
      return this.connection;
    }

    this.serviceAccessKey = serviceAccessKey;
    const connectionSource = new AxiosSignedConnectionSource(serviceAccessKey.identity);
    this.connection = await connectionSource.getSignedConnection('lambda');

    return this.connection;
  }

  /**
   * Gets the configuration for the Shutterstock service which most importantly
   * gives us the URL for the service's API.
   */
  private async getServiceConfiguration(
    serviceAccessKey: WaymarkServiceAccessKey,
  ): Promise<Service> {
    if (!serviceAccessKey) {
      throw new Error('Could not find service access token for ShutterstockService');
    }

    if (this.serviceAccessKey !== serviceAccessKey) {
      this.serviceAccessKey = serviceAccessKey;
      const serviceDiscovery = new ServiceDiscoverySDK(serviceAccessKey);
      this.serviceConfiguration = await serviceDiscovery.discoverService('Shutterstock');
    }

    if (!this.serviceConfiguration) {
      throw new Error('Unable to find service configuration for Search');
    }

    return this.serviceConfiguration;
  }

  /// SHUTTERSTOCK IMAGES

  /**
   * Fetches search result images from Shutterstock for a given set of search parameters.
   */
  async searchImages(
    searchParameters: ImageSearchQueryParameters,
    serviceAccessKey: WaymarkServiceAccessKey,
  ): Promise<AssetSearchResponse<Image>> {
    const serviceConfiguration = await this.getServiceConfiguration(serviceAccessKey);

    const url = new URL('/image/search', serviceConfiguration.baseURL);
    // Apply all search params to the URL
    for (const [parameter, value] of Object.entries(searchParameters)) {
      url.searchParams.set(parameter, `${value}`);
    }
    url.searchParams.sort();

    const connection = await this.getConnection(serviceAccessKey);

    const response = await connection.get<AssetSearchResponse<Image>>(url.toString());
    return response.data;
  }

  /**
   * Procures images from Shutterstock for the given purpose (usually either "preview" for drafts or "render" for purchased videos)
   * This method handles both licensing and downloading the assets in a single call.
   */
  async procureImages(
    images: Array<Image>,
    purpose: AssetLicensePurpose,
    serviceAccessKey: WaymarkServiceAccessKey,
  ): Promise<ProcureImageResponse> {
    const accountGUID = selectors.getAccountGUID(store.getState());

    const payload: ProcureImageRequest = {
      images: images.map((image) => ({
        sourceAsset: image,
        licensee: accountGUID,
        purpose,
        // The payload needs a size but we pretty much never want anything but the full size image,
        // so just go with that
        size: ImageSize.Huge,
      })),
    };

    const serviceConfiguration = await this.getServiceConfiguration(serviceAccessKey);

    const url = new URL('/image/procure', serviceConfiguration.baseURL);

    const connection = await this.getConnection(serviceAccessKey);

    const response = await connection.post<ProcureImageResponse>(url.toString(), payload);

    return response.data;
  }

  /// END SHUTTERSTOCK IMAGES

  /// SHUTTERSTOCK VIDEO

  /**
   * Fetches search result videos from Shutterstock for a given set of search parameters.
   */
  async searchVideos(
    searchParameters: VideoSearchQueryParameters,
    serviceAccessKey: WaymarkServiceAccessKey,
  ) {
    const serviceConfiguration = await this.getServiceConfiguration(serviceAccessKey);

    const url = new URL('/video/search', serviceConfiguration.baseURL);
    // Apply all search params to the URL
    for (const [parameter, value] of Object.entries(searchParameters)) {
      url.searchParams.set(parameter, `${value}`);
    }
    url.searchParams.sort();

    const connection = await this.getConnection(serviceAccessKey);

    const response = await connection.get<AssetSearchResponse<Video>>(url.toString());
    return response.data;
  }

  /**
   * Procures videos from Shutterstock for the given purpose
   * This method handles both licensing and downloading the assets in a single call.
   */
  async procureVideos(
    videos: Array<Video>,
    purpose: AssetLicensePurpose,
    serviceAccessKey: WaymarkServiceAccessKey,
  ): Promise<ProcureVideoResponse> {
    const accountGUID = selectors.getAccountGUID(store.getState());

    // Add the main request
    const request: ProcureVideoRequest = {
      videos: videos.map((video) => ({
        sourceAsset: video,
        licensee: accountGUID,
        purpose,
        size: VideoSize.HighDefinition,
      })),
    };

    const serviceConfiguration = await this.getServiceConfiguration(serviceAccessKey);
    const url = new URL('/video/procure', serviceConfiguration.baseURL);
    const connection = await this.getConnection(serviceAccessKey);

    const response = await connection.post<ProcureVideoResponse>(url.toString(), request);

    // Return the main response (the last one in our array)
    return response.data;
  }
}

export default new ShutterstockService();
